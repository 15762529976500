<template>
  <div class='container-modal-remove'>
    <div class="container-modal-remove">
      <div class="form-new-brand-remove">
        <div class="header-new-brand-remove">
          <p class="background-icon"><span class="exclamation-icon">!</span></p>
          <app-heading level="h6">{{ $t('bvs.want_to_remove') }}</app-heading>
          <app-heading level="h7" class="subtitle-modal">{{ $t('bvs.subtitle_to_remove') }}</app-heading>
        </div>
        <div class="footer-brand-remove">
          <button class="btn-brand-remove" @click="removeBrand()">
            <p v-if="!loading">{{ $t('bvs.delete_term') }}</p>
            <Circle2 v-else class="spinner-social"/>
          </button>
          <button class="btn-brand-remove cancel" @click="closeModalUpload()">{{ $t('bvs.cancel') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { socialMediaService } from '@/_services'

export default {
  name: 'ModalRemoveBrand',
  props: ['brand'],
  components: {
    'app-heading': () => import('@binds-tech/binds-design-system/src/components/Typography/Heading'),
    Circle2: () => import('vue-loading-spinner/src/components/Circle2')
  },
  data () {
    return {
      loading: false,
      nameCustomBrand: ''
    }
  },
  mounted () {
    const modal = document.querySelector('.form-new-brand-remove')
    setTimeout(() => { modal.classList.add('display') }, 100)
  },
  computed: {
    account () {
      return this.$store.getters['account/getAccount']
    }
  },
  methods: {
    async removeBrand () {
      this.loading = true
      if (this.brand && this.brand._id) {
        const brands = await socialMediaService.deleteBrands([this.brand._id])
        setTimeout(() => {
          this.closeModalUpload()
          this.$store.commit('account/updateBrandsTracking', brands)
          this.loading = false
        }, 1000)
      } else {
        setTimeout(() => {
          this.$root.$emit('remove-brand-searched', this.brand)
          this.loading = false
          this.closeModalUpload()
        }, 1000)
      }
    },
    closeModalUpload () {
      const modal = document.querySelector('.form-new-brand-remove')
      modal.classList.remove('display')
      setTimeout(() => { this.$root.$emit('close-brand-action-modals') }, 300)
    }
  }
}
</script>

<style lang='scss'>
.container-modal-remove {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0000004a;
  display: flex;
  align-items: center;
  justify-content: center;
  .form-new-brand-remove {
    opacity: 0;
    transition: opacity 0.3s;
    user-select: none;
    position: relative;
    width: 25%;
    height: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    .header-new-brand-remove {
      width: 100%;
      height: 80%;
      border-bottom: 1px solid #cacaca;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .background-icon {
        width: 50px;
        height: 50px;
        background: #ff000021;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .exclamation-icon {
          width: 50%;
          height: 50%;
          border: 1px solid #ef5959;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 17px;
          font-weight: 500;
          color: #ef5959;
        }
      }
      h6 {
        text-align: center;
        font-weight: 500;
        color: #454548;
        margin: 15px 0 !important;
      }
      .subtitle-modal {
        color: #858585;
        font-size: 13px;
      }
    }
    .footer-brand-remove {
      width: 100%;
      height: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F0F2F5;
      .btn-brand-remove {
        width: 90px;
        height: 30px;
        margin: 0 5px;
        padding: 5px 7px;
        border-radius: 3px;
        background: var(--accent-color);
        color: var(--default-text-color);
        font-weight: 500;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        .spinner-social {
          width: 20px !important;
          height: 20px !important;
          border-width: 2px !important;
          border-color: var(--accent-color) var(--default-text-color) var(--default-text-color) !important;
        }
      }
      .cancel {
        background: #ffffff;
        color: var(--accent-color);
        border: 1px solid #cacaca;
        font-weight: 600;
      }
    }
  }
  .display {
    opacity: 1;
  }
}
</style>
